import { Button, ButtonProps, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const StyledButton = withStyles(({ palette }) => ({
  root: {
    flex: "0 0 120px",
    fontWeight: 700,
    borderRadius: "px",
    background: 'linear-gradient(93.5deg,#f89947 0%, #e730a8  31.67%, #7327d7 66.07%, #0a76eb 100%)',
    color:"#fff",
    boxShadow: "0 4px 12px 0 #050C11",
    letterSpacing: "0.88px",
    "&:hover": {
      background: 'linear-gradient(93.5deg,  #0a76eb, #e730a8)',
      color:"#Fff",
    },
    "&:disabled": {
      color: "#fff",
      background: '#858c94',
      boxShadow: "none",
    
    },
  },
}))(Button);

interface Props extends ButtonProps {
  loading?: boolean;
}

export default function PrimaryButton({
  loading = false,
  disabled,
  children,
  ...props
}: Props) {
  return (
    <StyledButton disabled={loading || disabled} {...props}>
      {loading ? <CircularProgress color="inherit" size={24} thickness={2} /> : children}
    </StyledButton>
  );
}
