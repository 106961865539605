import { Box, Backdrop, Typography, BackdropProps } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const StyledBackdrop = withStyles({
  root: {
    position: "absolute",
    zIndex: 1,
    fontSize: 22,
    textAlign: "center",
    backgroundColor: "rgba(0, 0, 0, 0.85)",
  },
})(Backdrop);

export default function Pending({
  children,
  ...props
}: Omit<BackdropProps, "open">) {
  return (
    <StyledBackdrop open {...props}>
      <Box>
        <Typography variant='h6' display='block'>
          This pool will go live after <br />
          Spot Trading launch which is<br />
          <small>on July, 10th 2021 at 6:00 PM UTC</small>
        </Typography>
      </Box>
    </StyledBackdrop>
  );
}
