/** @format */

import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FaSearch, FaTelegramPlane } from "react-icons/fa";

function Header() {
  return (
    <Navbar expand='lg' sticky='top' className='navbar'>
      <Container>
        <Navbar.Brand href='#home'>
          <img src='assets/logo.svg' alt='' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='m-auto pt-1'>
            <Nav.Link href='https://ai2.club'>Home</Nav.Link>
            <Nav.Link
              target='_blank'
              rel='noopener noreferrer'
              href='https://squareai.gitbook.io/square-ai'>
              Explore
            </Nav.Link>
            <Nav.Link
              target='_blank'
              rel='noopener noreferrer'
              className='active'
              href='https://vote.ai2.club'>
              Staking
            </Nav.Link>
            <Nav.Link
              target='_blank'
              rel='noopener noreferrer'
              href='https://earn.ai2.club'>
              Governance
            </Nav.Link>
            <Nav.Link
              target='_blank'
              rel='noopener noreferrer'
              href='https://chat.ai2.club'>
              Query
            </Nav.Link>
            <Nav.Link
              target='_blank'
              rel='noopener noreferrer'
              href='https://art.ai2.club'>
              Generate
            </Nav.Link>
          </Nav>
          <a
            href='https://t.me/AI2_CLUB'
            target='_blank'
            rel='noopener noreferrer'>
            <button className='nav-btn mx-3'>
              <FaTelegramPlane color='#858C94' /> &nbsp; Join The Telegram
            </button>
          </a>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
