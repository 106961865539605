import { useUpdateAtom } from "jotai/utils";
import { Button, ButtonProps } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { openAtom } from "@atoms/wallet";

interface Props extends ButtonProps {
  rounded?: boolean;
}

const StyledButton = withStyles(({ palette }) => ({
  root: {
    fontWeight: 700,
    borderRadius: 16,
    color: "#212529"
  },
}))(Button);

const useStyles = makeStyles({
  rounded: {
    borderRadius: "7px",
    // background: '#1B2130',
    background: 'linear-gradient(93.5deg,#f89947 0%, #e730a8  31.67%, #7327d7 66.07%, #0a76eb 100%)',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
    color:"#fff",
    fontWeight:700,
    // borderWidth: '1px',
    // borderStyle: 'solid',
    // borderImage: 'linear-gradient(to right, #FF9900, #38D7CD) ',
    "&:hover": {
      background: 'linear-gradient(93.5deg,  #0a76eb, #e730a8)',
      color:"#fff",
      // border: '1px solid #FF9900'
    },
  },
});

const isAvailable = false;

export default function ConnectButton({ rounded, ...props }: Props) {
  const classes = useStyles();

  const setOpen = useUpdateAtom(openAtom);

  return (
    <StyledButton
      variant='contained'
      color='primary'
      size='small'
      onClick={() => setOpen(true)}
      disabled={!isAvailable}
      {...props}
      className={rounded ? classes.rounded : ""}
    >
      {isAvailable ? "Connect Wallet" : "Available Soon"}
    </StyledButton>
  );
}
