import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "@material-ui/core";
import useRequest from "@ahooksjs/use-request";
import StakeCard, { StakeCardProps } from "@components/StakeCard";
import { Token, TOKEN_ADDRESS, Action } from "@utils/constants";
import useWallet from "@hooks/useWallet";
import useFetchStats from "@hooks/useFetchStats";
import useFetchState from "@hooks/useFetchState";
import JITBg from "@assets/bgs/AI2.png";
import AI2BNBLp from "@assets/bgs/AI2-bnb-lp.png";
import AI2BusdLp from "@assets/bgs/AI2-busd-lp.png";
import bnbBg from "@assets/bgs/bnb.png";
import cakeLogo from "@assets/tokens/cake.png";
import AI2Logo from "@assets/tokens/AI2.png";

const useStyles = makeStyles({
  container: {
    padding: "6vh 24px 24px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  card: {
    margin: 10,
  },
});

const cards: StakeCardProps[] = [
  {
    token: Token.JIT,
    bg: JITBg,
    color: "#fff",
    cardTitle:"AI2 Pool",
    desc: (
      <>
        <br />
        Stake $AI2 to earn AI2. <br />
      </>
    ),
      // links: [
      //   {
      //     href: `https://exchange.pancakeswap.finance/#/swap?outputCurrency=${
      //       TOKEN_ADDRESS[56][Token.JIT]
      //     }`,
      //     logo: cakeLogo,
      //     text: "Buy AI2",
      //   },
      // ],
  },
  {
    token: Token.JIT_BNB_LP,
    bg: JITBg,
    color: "#fff",
    cardTitle: "BNB Lp Pool",

    desc: (
      <>
        <br />

        Stake AI2-BNB LPs to earn AI2. <br />
  
      </>
    ),
    // links: [
    //   {
    //     href: `https://exchange.pancakeswap.finance/#/add/BNB/${
    //       TOKEN_ADDRESS[56][Token.JIT]
    //     }`,
    //     logo: cakeLogo,
    //     text: "GET AI2-BNB LP TOKENS",
    //   },
    // ],
  },
  {
    token: Token.JIT_AI2_LP,
    bg: JITBg,
    color: "#fff",
    cardTitle: "BUSD Pool",
    desc: (
      <>
        <br />

        Stake AI2-BUSD to earn AI2.
  
      </>
    ),
    // links: [
    //   {
    //     href: `https://app.MetaAI2.finance/add/ETH?network=56&outputCurrency=${
    //       TOKEN_ADDRESS[56][Token.JIT]
    //     }`,
    //     logo: AI2Logo,
    //     text: "GET AI2-BNB LP TOKENS",
    //   },
    // ],
  },
  // {
  //   token: Token.JIT_AI2_BUSD_LP,
  //   bg: AI2BusdLp,
  //   color: "#fff",
  //   cardTitle: "Stake AI2-BUSD LP",
  //   desc: (
  //     <>
  //       Stake AI2-BUSD LP to earn AI2.
  //       You can provide liquidity on MetaAI2 to get AI2-LP tokens.
  //     </>
  //   ),
  //   links: [
  //     {
  //       href: `https://app.MetaAI2.finance/add/ETH?network=56&outputCurrency=${
  //         TOKEN_ADDRESS[56][Token.JIT]
  //       }`,
  //       logo: AI2Logo,
  //       text: "GET AI2-BUSD LP TOKENS",
  //     },
  //   ],
  // },
];

export default function Home() {
  const classes = useStyles();

  const { connected } = useWallet();

  const fetchStats = useFetchStats();
  const fetchState = useFetchState();

  useRequest(fetchStats, {
    loadingDelay: 500,
    pollingInterval: 10000,
    pollingWhenHidden: false,
    refreshOnWindowFocus: true,
    throttleInterval: 1000,
  });

  const { run, cancel } = useRequest(fetchState, {
    manual: true,
    loadingDelay: 500,
    pollingInterval: 10000,
    pollingWhenHidden: false,
    refreshOnWindowFocus: true,
    throttleInterval: 1000,
  });

  useEffect(() => {
    if (connected) {
      run();
    } else {
      cancel();
    }
  }, [cancel, connected, run]);

  return (
    <div className={classes.container}>
      {cards.map((card) => (
        <StakeCard key={card.token} {...card} className={classes.card} />
      ))}
    </div>
  );
}
